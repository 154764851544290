.page_header{
    background-color: #fff;
    border-bottom: 1px solid #D9D9D9;
    margin-top: 9px;
}

.page_header span{
    font-weight: 500;
    font-size: 1rem;
    
}

/* Table Header */
.ant-table-thead > tr > th{
    font-size: 0.875rem;
}

/* Pagination link */

.ant-pagination-item-active a{
    color: #000;
    
}

.ant-pagination-item-active{
    border-color: #000;
}

.page_header_admin{
    background-color: #fff;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 20px;
}
.ant-layout-sider-trigger{
    text-align: left;
    padding-left: 16.66px;
}
.ant-layout-sider-trigger .anticon svg{
    opacity: 0;
}

.ant-layout-sider-trigger .anticon{
    background-image: url('../../assets/icons/collapse.svg');
    background-size: cover;
    background-repeat: no-repeat;
}

.ant-menu-inline-collapsed .ant-menu-title-content{
    opacity: 0;
}



.ant-picker-input > input:placeholder-shown{
    font-size: 00.975rem;
    color:rgba(0,0,0,0.3);
}

.ant-select-item-option{
    width: 100%;
}

.ant-switch-checked{
    background-color: #000;
} 

.ant-switch{
    background-color: #000;
}

.ant-form-item-explain.ant-form-item-explain-error {
    font-size: 0.775rem;
}

.ant-form-item-children-icon svg{
    display: none;
}

.ant-picker-input > input:placeholder-shown{
	font-size: 0.800rem;
}

.popselect .ant-popover-title{
    display: none;
}

.ant-popconfirm .ant-popover-inner-content{
    position: relative;
}

.ant-popover-inner-content{
    position: absolute;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
    color: #000;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after{  
     border-bottom:2px solid #000;
}

.ant-upload.ant-upload-select{
    display: block;
}

.ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-text-icon > .anticon, .ant-upload-list-item-error .ant-upload-list-item-name{
    color: #000;
}

.upload-box .ant-tooltip-content {
    display: none;
}

.ant-menu-submenu-selected{
    color: #000;
}

.ant-menu-submenu:hover{
    color:#000;
}

.showTable .ant-table table{
    white-space: break-spaces;
}

.ant-picker-ranges .ant-picker-ok{
    display: none;
}

.ant-drawer-content {
    overflow-y: scroll;
  }

.referral_form{
    width:100% !important;
}

.mybroadcastupload{
    width: 100%;
    border:1px dotted red;
}

.rider_radio .ant-radio-inner{
    width: 40px;
    height: 40px;
    border: 1px solid #D9D9D9;
    border-radius: 50%;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    background-color: #c7d0d8;
}


.rider_radio .ant-radio-inner::after{
    height: 40px;
    width: 40px;
    top: 0 !important;
    left:0;
    background-color: #000;
    border-radius: 50%;
}
.not-started{
    background-color: #000;
}

.rider_radio .ant-radio{
    margin-right: 30px;
    top:1.5em;
}
.rider_radio span.ant-radio + *{
    top:0;
    left:-12px;
    position: absolute;
    display: block;
    width:112px;
    padding-right:0;
    padding-left: 0;
    font-size: 12px;
}

.user_form .ant-form-item-label{
    text-align: left !important;
    width:100% !important;
    margin-bottom: -3px;
}

.ant-layout-sider-light,.ant-menu-vertical{
    background: orange;
}

.ant-layout-sider-light .ant-layout-sider-trigger{
    background: orange;
}


.ant-tooltip-inner a{
    color:#000;
}

.ant-btn:hover, .ant-btn:focus, .ant-btn:active{
    color: orange;
    border-color: orange;
}

.selected_btn_active{
	color: orange !important;
	border-color: orange !important;
}

.ant-page-header-ghost{
    background: #fff;
}

.popup-overlay {
    z-index: 1000 !important;
    width:420px;
    left: 75% !important;
}

.inspectionquestion{
padding-left: 10px;
}

.driver{
    border:none;
    border-bottom: 1px solid #d9d9d9;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color:orange !important;
    border-color: orange !important;
}

.ant-radio-button-wrapper .ant-radio-button-wrapper-checked,.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before{
    background-color: orange !important;
}

.ant-radio-button-wrapper .ant-radio-button-wrapper-checked,.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
    color:orange !important;
    border-color: orange !important;

}

.ant-select{
    width:100% !important;
}

.resolution_date{
    height: 32px !important;
}

.ant-upload-list-item-card-actions-btn:focus, .ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn{
    opacity: 1;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary){
    height: 32px;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child){
    height: 32px;
    padding: 0px 0px 0px 10px;
}

.upload-btn .ant-upload-list-item-info{
    display: none;
}