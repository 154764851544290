@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

@font-face {
  font-family: 'Roboto-Regular';
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;&display=swap');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto-SemiBold';
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@600;&display=swap');
  font-weight: 600;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@800&display=swap');
  font-weight: 800;
}

@font-face {
  font-family: 'Roboto-Black';
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
  font-weight: 900;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;&display=swap');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto-ExtraLight';
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@200&display=swap');
  font-weight: 200;
}

div.ant-row.ant-form-item {
  width: 88%;
}