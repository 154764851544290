@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: 'Roboto',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Ok button*/
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
	font-family: 'Poppins-Regular' !important;
	font-size: 12px !important;
	background: #000 !important;
	color: #fff !important;
	border-radius: 0px !important;
}

.ant-btn-primary {
	background: #000 !important;
	color: #fff !important;
	border-color: #000 !important;
	border-radius: 0px !important;
}

.ant-picker {
	height: 40px !important;
	border-radius: 0px !important;
	border: 1px solid rgb(222, 228, 229);
	border-right-width: 1px;
	width: 100%;
	margin-bottom: 30px;
	background-color: rgb(252, 252, 252);
}

/*Calander*/
.ant-picker-panel-container {
	width: 100% !important;
	font-family: 'Poppins-Medium' !important;
	font-size: 12px !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09) !important;
	border: none !important;
	color: #0c0442 !important;
	border-radius: 0px !important;
}

/*Content*/
.ant-picker-content th {
	color: #0c0442 !important;
	font-family: 'Poppins-Regular' !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	margin: 0.5rem !important;
}

.ant-picker-cell-inner {
	font-size: 12px !important;
	/* color: #0c0442; */
	font-family: 'Poppins-Regular' !important;
	font-weight: 400 !important;
	margin: 0.5rem !important;
}

.ant-picker-cell-today {
	border-radius: 50% !important;
	border-color: #000 !important;
}

/*Selected Range Colors*/

.ant-picker-cell-range-hover::before {
	background: #000 !important;
	color: #fff !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
	.ant-picker-cell-inner {
	border-radius: 25% !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
	.ant-picker-cell-inner {
	border-radius: 25% !important;
}

.ant-picker-cell-range-hover .ant-picker-cell-inner {
	color: #fff !important;
}

.ant-picker-cell-range-hover-start::before {
	background: #000 !important;
	color: #fff !important;
}

.ant-picker-cell-range-hover-end::before {
	background: #000 !important;
	color: #fff !important;
}

.ant-picker-cell-range-hover-start .ant-picker-cell-inner {
	background: #000 !important;
	color: #fff !important;
}

.ant-picker-cell-range-start .ant-picker-cell-inner {
	background: #000 !important;
	color: #fff !important;
}

.ant-picker-cell-range-hover-end .ant-picker-cell-inner {
	background: #000 !important;
	color: #fff !important;
}

.ant-picker-cell-in-range::before {
	background: #000 !important;
	color: #fff !important;
}

.ant-picker-cell-in-range .ant-picker-cell-inner {
	color: #fff !important;
}

.ant-picker-cell-in-view .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
	background: #000 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
	background: #000 !important;
}

.ant-picker-header-super-next-btn,
.ant-picker-header-super-prev-btn {
	display: none !important;
}

/*calendar header*/
.ant-picker-header-view,
.ant-picker-header-view {
	font-family: 'Poppins-SemiBold';
	font-size: 14px !important;
	color: #0c0442 !important;
}

/* Time Panel*/

.ant-picker-time-panel-column
	> li.ant-picker-time-panel-cell
	.ant-picker-time-panel-cell-inner {
	font-size: 12px !important;
	color: #0c0442 !important;
	font-family: 'Poppins-Regular' !important;
	font-weight: 400 !important;
}

.ehuddle-date-picker-style {
	height: 40px !important;
	border-radius: 0px !important;
}

.phone-input {
  width: 100% !important;
  font-family: Poppins-Medium !important;
  border-radius: 4px !important;
  border-width: 1px !important;
  font-size: 1.25rem !important;
  box-sizing: border-box;
  padding-top: 1.2rem !important;
  padding-left: 50px !important;
  padding-bottom: 1.2rem !important;
  border: 1px solid !important;
  outline: none;
  color: #0c0442 !important;
  height: 44px !important;
  border-radius: 6px !important;
  background-color: #fcfcfc !important;
  position: relative;
  border-color: #dee4e5 !important;
}
.error {
  border-color: #9b3634 !important;
  background-color: #fff1f1 !important;
}

.error .flag-dropdown {
  border-color: #9b3634 !important;
  background-color: #fff1f1 !important;
}
.flag-dropdown{
  width: 90px !important;
  height: 40px;
  border-radius: 2px;
}

/*Ok button*/
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
	font-family: 'Poppins-Regular' !important;
	font-size: 12px !important;
	background: #000000 !important;
	color: #fff !important;
	border-radius: 0px !important;
}

.ant-btn-primary {
	background: #000000 !important;
	color: #fff !important;
	border-color: #000000 !important;
	border-radius: 0px !important;
}

/*Calander*/
.ant-picker-panel-container {
	width: 100% !important;
	font-family: 'Poppins-Medium' !important;
	font-size: 12px !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09) !important;
	border: none !important;
	color: #0c0442 !important;
	border-radius: 0px !important;
}

/*Content*/
.ant-picker-content th {
	color: #0c0442 !important;
	font-family: 'Poppins-Regular' !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	margin: 0.5rem !important;
}

.ant-picker-cell-inner {
	font-size: 12px !important;
	/* color: #0c0442; */
	font-family: 'Poppins-Regular' !important;
	font-weight: 400 !important;
	margin: 0.5rem !important;
}

.ant-picker-cell-today {
	border-radius: 50% !important;
	border-color: #000000 !important;
}

/*Selected Range Colors*/

.ant-picker-cell-range-hover::before {
	background: #000000 !important;
	color: #fff !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
	.ant-picker-cell-inner {
	border-radius: 25% !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
	.ant-picker-cell-inner {
	border-radius: 25% !important;
}

.ant-picker-cell-range-hover .ant-picker-cell-inner {
	color: #fff !important;
}

.ant-picker-cell-range-hover-start::before {
	background: #00000 !important;
	color: #fff !important;
}

.ant-picker-cell-range-hover-end::before {
	background: #000000 !important;
	color: #fff !important;
}

.ant-picker-cell-range-hover-start .ant-picker-cell-inner {
	background: #000000 !important;
	color: #fff !important;
}

.ant-picker-cell-range-start .ant-picker-cell-inner {
	background: #000000 !important;
	color: #fff !important;
}

.ant-picker-cell-range-hover-end .ant-picker-cell-inner {
	background: #000000 !important;
	color: #fff !important;
}

.ant-picker-cell-in-range::before {
	background: #000000 !important;
	color: #fff !important;
}

.ant-picker-cell-in-range .ant-picker-cell-inner {
	color: #fff !important;
}

.ant-picker-cell-in-view .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
	background: #000000 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
	background: #000000 !important;
}

.ant-picker-header-super-next-btn,
.ant-picker-header-super-prev-btn {
	display: none !important;
}

/*calendar header*/
.ant-picker-header-view,
.ant-picker-header-view {
	font-family: 'Poppins-SemiBold';
	font-size: 14px !important;
	color: #0c0442 !important;
}

/* Time Panel*/

.ant-picker-time-panel-column
	> li.ant-picker-time-panel-cell
	.ant-picker-time-panel-cell-inner {
	font-size: 12px !important;
	color: #0c0442 !important;
	font-family: 'Poppins-Regular' !important;
	font-weight: 400 !important;
}

.ehuddle-date-picker-style {
	height: 40px !important;
	border-radius: 0px !important;
}


/* to hide second calendar */
.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
	visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
	visibility: visible !important;
}

.ant-picker-panels > *:last-child {
	display: none !important;
}

.ant-picker-panel-container,
.ant-picker-footer {
	width: 280px !important;
}

.ant-picker-footer-extra > div {
	flex-wrap: wrap !important;
}


textarea.ant-input {
    border-radius: 2px;
}
.otpStyle{
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
}

.otpStyle div{
    width: 74px;
    height: 56px;
    border: 1px solid #000000;
    border-radius: 3px;
    margin-left: 1px;
}
.otpStyle input{
    width: 63px !important;
    height: 44px;
    margin: 0px 4px;
    font-size: 20px;
    border:none;
    border-bottom: 1px solid #C4C4C4;
}
.page404{
    background: #FFF;
}


/* Table Header */
.ant-table-thead > tr > th{
    font-size: 0.875rem;
}

/* Pagination link */

.ant-pagination-item-active a{
    color: #000;
    
}

.ant-pagination-item-active{
    border-color: #000;
}
.page_header{
    background-color: #fff;
    border-bottom: 1px solid #D9D9D9;
    margin-top: 9px;
}

.page_header span{
    font-weight: 500;
    font-size: 1rem;
    
}

/* Table Header */
.ant-table-thead > tr > th{
    font-size: 0.875rem;
}

/* Pagination link */

.ant-pagination-item-active a{
    color: #000;
    
}

.ant-pagination-item-active{
    border-color: #000;
}

.page_header_admin{
    background-color: #fff;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 20px;
}
.ant-layout-sider-trigger{
    text-align: left;
    padding-left: 16.66px;
}
.ant-layout-sider-trigger .anticon svg{
    opacity: 0;
}

.ant-layout-sider-trigger .anticon{
    background-image: url(/static/media/collapse.2e1233b4.svg);
    background-size: cover;
    background-repeat: no-repeat;
}

.ant-menu-inline-collapsed .ant-menu-title-content{
    opacity: 0;
}



.ant-picker-input > input:placeholder-shown{
    font-size: 00.975rem;
    color:rgba(0,0,0,0.3);
}

.ant-select-item-option{
    width: 100%;
}

.ant-switch-checked{
    background-color: #000;
} 

.ant-switch{
    background-color: #000;
}

.ant-form-item-explain.ant-form-item-explain-error {
    font-size: 0.775rem;
}

.ant-form-item-children-icon svg{
    display: none;
}

.ant-picker-input > input:placeholder-shown{
	font-size: 0.800rem;
}

.popselect .ant-popover-title{
    display: none;
}

.ant-popconfirm .ant-popover-inner-content{
    position: relative;
}

.ant-popover-inner-content{
    position: absolute;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
    color: #000;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after{  
     border-bottom:2px solid #000;
}

.ant-upload.ant-upload-select{
    display: block;
}

.ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-text-icon > .anticon, .ant-upload-list-item-error .ant-upload-list-item-name{
    color: #000;
}

.upload-box .ant-tooltip-content {
    display: none;
}

.ant-menu-submenu-selected{
    color: #000;
}

.ant-menu-submenu:hover{
    color:#000;
}

.showTable .ant-table table{
    white-space: break-spaces;
}

.ant-picker-ranges .ant-picker-ok{
    display: none;
}

.ant-drawer-content {
    overflow-y: scroll;
  }

.referral_form{
    width:100% !important;
}

.mybroadcastupload{
    width: 100%;
    border:1px dotted red;
}

.rider_radio .ant-radio-inner{
    width: 40px;
    height: 40px;
    border: 1px solid #D9D9D9;
    border-radius: 50%;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    background-color: #c7d0d8;
}


.rider_radio .ant-radio-inner::after{
    height: 40px;
    width: 40px;
    top: 0 !important;
    left:0;
    background-color: #000;
    border-radius: 50%;
}
.not-started{
    background-color: #000;
}

.rider_radio .ant-radio{
    margin-right: 30px;
    top:1.5em;
}
.rider_radio span.ant-radio + *{
    top:0;
    left:-12px;
    position: absolute;
    display: block;
    width:112px;
    padding-right:0;
    padding-left: 0;
    font-size: 12px;
}

.user_form .ant-form-item-label{
    text-align: left !important;
    width:100% !important;
    margin-bottom: -3px;
}

.ant-layout-sider-light,.ant-menu-vertical{
    background: orange;
}

.ant-layout-sider-light .ant-layout-sider-trigger{
    background: orange;
}


.ant-tooltip-inner a{
    color:#000;
}

.ant-btn:hover, .ant-btn:focus, .ant-btn:active{
    color: orange;
    border-color: orange;
}

.selected_btn_active{
	color: orange !important;
	border-color: orange !important;
}

.ant-page-header-ghost{
    background: #fff;
}

.popup-overlay {
    z-index: 1000 !important;
    width:420px;
    left: 75% !important;
}

.inspectionquestion{
padding-left: 10px;
}

.driver{
    border:none;
    border-bottom: 1px solid #d9d9d9;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color:orange !important;
    border-color: orange !important;
}

.ant-radio-button-wrapper .ant-radio-button-wrapper-checked,.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before{
    background-color: orange !important;
}

.ant-radio-button-wrapper .ant-radio-button-wrapper-checked,.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
    color:orange !important;
    border-color: orange !important;

}

.ant-select{
    width:100% !important;
}

.resolution_date{
    height: 32px !important;
}

.ant-upload-list-item-card-actions-btn:focus, .ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn{
    opacity: 1;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary){
    height: 32px;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child){
    height: 32px;
    padding: 0px 0px 0px 10px;
}

.upload-btn .ant-upload-list-item-info{
    display: none;
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;&display=swap');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto-SemiBold';
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@600;&display=swap');
  font-weight: 600;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@800&display=swap');
  font-weight: 800;
}

@font-face {
  font-family: 'Roboto-Black';
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
  font-weight: 900;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;&display=swap');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto-ExtraLight';
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@200&display=swap');
  font-weight: 200;
}

div.ant-row.ant-form-item {
  width: 88%;
}
