.phone-input {
  width: 100% !important;
  font-family: Poppins-Medium !important;
  border-radius: 4px !important;
  border-width: 1px !important;
  font-size: 1.25rem !important;
  box-sizing: border-box;
  padding-top: 1.2rem !important;
  padding-left: 50px !important;
  padding-bottom: 1.2rem !important;
  border: 1px solid !important;
  outline: none;
  color: #0c0442 !important;
  height: 44px !important;
  border-radius: 6px !important;
  background-color: #fcfcfc !important;
  position: relative;
  border-color: #dee4e5 !important;
}
.error {
  border-color: #9b3634 !important;
  background-color: #fff1f1 !important;
}

.error .flag-dropdown {
  border-color: #9b3634 !important;
  background-color: #fff1f1 !important;
}
.flag-dropdown{
  width: 90px !important;
  height: 40px;
  border-radius: 2px;
}
