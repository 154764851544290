.otpStyle{
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
}

.otpStyle div{
    width: 74px;
    height: 56px;
    border: 1px solid #000000;
    border-radius: 3px;
    margin-left: 1px;
}
.otpStyle input{
    width: 63px !important;
    height: 44px;
    margin: 0px 4px;
    font-size: 20px;
    border:none;
    border-bottom: 1px solid #C4C4C4;
}